import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import { useRouter } from "next/router";
import { useState } from "react";

import {
	Logo,
	MobileMenuButton,
	MobileMenuContainer,
	NavbarButton,
	NavbarContainer,
	QrImage
} from "./Navbar.styled";
import { FlexRow } from "../../styles/flex.styled";
import BurgerMenu from "./BurgerMenu";

const routeButtons = [
	{
		title: "Home",
		href: "/"
	},
	{
		title: "Support",
		href: "/support"
	}
];

export default function Navbar() {
	const [mobileMenuOpen, setMobileMenuOpen] = useState();
	const router = useRouter();

	const handleOpenMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	};

	return (
		<>
			<NavbarContainer>
				<Link href="/">
					<FlexRow style={{ minWidth: 125, cursor: "pointer" }}>
						<ExportedImage src="/assets/icon_white.png" height={30} width={30} alt="Wishbox" />
						<Logo>Wishbox</Logo>
					</FlexRow>
				</Link>
				<QrImage>
					<ExportedImage src="/assets/qr_code.png" height={350} width={350} alt="" />
				</QrImage>
				<FlexRow>
					{routeButtons.map(({ href, title }) => (
						<Link href={href} key={title}>
							<NavbarButton selected={router.pathname === href}>{title}</NavbarButton>
						</Link>
					))}
					<BurgerMenu onClick={handleOpenMobileMenu} />
				</FlexRow>
			</NavbarContainer>
			{mobileMenuOpen && (
				<MobileMenuContainer>
					{routeButtons.map(({ href, title }) => (
						<Link href={href} key={title}>
							<MobileMenuButton>{title}</MobileMenuButton>
						</Link>
					))}
				</MobileMenuContainer>
			)}
		</>
	);
}
