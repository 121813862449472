import styled from "styled-components";
import { css } from "styled-components";

export const Section = styled.div`
	display: flex;
	padding: 60px;
	justify-content: center;

	@media (max-width: 768px) {
		padding: 30px;
	}
`;

export const SectionLarge = styled.div`
	display: flex;
	max-width: 1800px;
`;

export const SectionMedium = styled.div`
	display: flex;
	max-width: 1300px;
`;

export const SectionSmall = styled.div`
	display: flex;
	max-width: 900px;
`;

export const Article = styled.div`
	padding: 0 50px 50px 50px;

	@media (max-width: 768px) {
		padding: 0 15px 25px 15px;
	}
`;

export const Text = styled.span`
	color: ${({ color }) => color ?? "#000"};

	${({ size }) =>
		size &&
		css`
			font-size: ${({ size }) => `${size}px !important`};
		`}

	&:hover {
		${({ hover }) => hover}
	}

	${({ header }) =>
		header &&
		css`
			font-size: 44px;
			line-height: 44px;
			font-weight: 700;
			font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

			@media (max-width: 768px) {
				font-size: 30px;
				line-height: 30px;
			}
		`}

	${({ extreme }) =>
		extreme &&
		css`
			font-size: 74px;
			line-height: 74px;
			font-weight: 900;
			font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

			@media (max-width: 768px) {
				font-size: 50px;
				line-height: 50px;
			}
		`}
`;

export const MobileHero = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`;

export const RetractableSection = styled.div``;
