import Head from "next/head";
import Link from "next/link";
import { useState, useEffect } from "react";
import { Section, Text } from "../styles/base.styled";

import Navbar from "./Navbar/Navbar";

export default function Layout({ title, children }) {
	const [mounted, setMounted] = useState(false);

	useEffect(() => setMounted(true), []);

	if (!mounted) return null;

	return (
		<>
			<Head>
				<title>{title}</title>
				<link rel="icon" href="/favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			</Head>
			<Navbar />
			{children}
			<footer>
				<Section
					style={{
						textAlign: "center",
						color: "white",
						backgroundColor: "#3e3e3e"
					}}
				>
					<Text color="#FFF">
						Copyright © 2022 Kostas Filios. All rights reserved. Read our
						<Link href="/terms">
							<a style={{ marginLeft: 6 }}>terms of service.</a>
						</Link>
					</Text>
				</Section>
			</footer>
		</>
	);
}
