import styled from "styled-components";

export const NavbarContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 20px 60px;
	height: 30px;
	align-items: center;
	justify-content: space-between;
	background-color: #3e3e3e;

	@media (max-width: 768px) {
		padding: 5px 30px;
		height: 70px;
	}
`;

export const Logo = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	line-height: 30px;
	font-size: 30px;
	margin-left: 10px;
	color: #eee;

	@media (max-width: 768px) {
		font-size: 20px;
	}

	/* I sould write background-clip: text; and work with -webkit-background-clip: text; automatically */
	/* background: -webkit-linear-gradient(45deg, #ba6bff, #f56772);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
`;

export const NavbarButton = styled.a`
	padding: 10px 15px;
	font-size: 22px;
	cursor: pointer;
	margin-left: 5px;

	color: ${({ selected }) => (selected ? "#333" : "#FFF")};
	background-color: ${({ selected }) => (selected ? "#DDD" : "transparent")};
	border-radius: 10px;

	@media (max-width: 768px) {
		display: none;
	}

	&:hover {
		color: #000;
		background-color: #fff;
	}
`;

export const MobileMenuContainer = styled.div`
	background-color: #3e3e3e;
`;

export const MobileMenuButton = styled.div`
	padding: 15px;
	text-align: center;
	font-size: 22px;
	color: #fff;
	border-top: 0.5px solid #aaa;
`;

export const QrImage = styled.div`
	display: flex;
	flex-direction: row;
	z-index: 10;
	transition: all 0.3s ease-in-out;
	border-radius: 35px;
	overflow: hidden;
	transform: scale(0.125) translate(0px, 0px);

	@media (max-width: 768px) {
		border-radius: 20px;
		transform: scale(0.25) translate(0px, 0px);
	}

	&:hover {
		transform: scale(1) translate(0px, 150px);
		box-shadow: 0 0 14px #00000077;

		@media (max-width: 768px) {
			transform: scale(1) translate(0px, 60px);
		}
	}
`;
