import styled from "styled-components";

export default function BurgerMenu({ ...rest }) {
	return (
		<Container {...rest}>
			<svg
				width="35"
				height="35"
				viewBox="0 0 24 24"
				fill="none"
				stroke="#fff"
				strokeWidth="2"
				strokeLinecap="butt"
				strokeLinejoin="arcs"
			>
				<line x1="3" y1="12" x2="21" y2="12"></line>
				<line x1="3" y1="6" x2="21" y2="6"></line>
				<line x1="3" y1="18" x2="21" y2="18"></line>
			</svg>
		</Container>
	);
}

export const Container = styled.div`
	display: none;

	@media (max-width: 768px) {
		display: flex;
	}
`;
