import ExportedImage from "next-image-export-optimizer";
import MobileStoreButton from "react-mobile-store-button";
import { useEffect, useState } from "react";
import useMobileDetect from "use-mobile-detect-hook";

import Layout from "../components/layout";
import { Article, SectionLarge, Section, Text, MobileHero } from "../styles/base.styled";
import { FlexContainer, FlexColumn } from "../styles/flex.styled";
import useWindowSize from "../shared/hooks/useWindowResize";

export default function Home() {
	const [isMobile, setIsMobile] = useState(false);

	const windowSize = useWindowSize();
	const detectMobile = useMobileDetect();

	useEffect(() => {
		setIsMobile(detectMobile.isMobile());
	}, [detectMobile, windowSize]);

	console.log("isMobile", isMobile);

	return (
		<Layout>
			<Section
				style={{
					position: isMobile ? "relative" : "absolute",
					...(isMobile && { paddingTop: 70 }),
					...(!isMobile && { marginTop: 70 }),
					right: 0,
					justifyContent: isMobile ? "center" : "flex-end"
				}}
			>
				<MobileStoreButton
					store="ios"
					url="https://apps.apple.com/us/app/wishbox-finance/id1517031842"
					linkProps={{ title: "iOS Store Button" }}
				/>
			</Section>
			<Section>
				<SectionLarge>
					<FlexContainer>
						<MobileHero>
							<div style={{ position: "absolute", paddingLeft: 33, paddingTop: 32 }}>
								<video autoPlay muted loop height={1170} preload="auto">
									<source
										src={require("../public/assets/preview.mp4")}
										poster="/assets/app_preview.png"
										type="video/mp4"
									/>
								</video>
							</div>
							<div style={{ position: "relative", width: 605 }}>
								<ExportedImage width={1200} height={2448} src="/assets/device.png" alt="" />
							</div>
						</MobileHero>
					</FlexContainer>
					<FlexColumn style={{ height: "100%", display: "flex", justifyContent: "space-evenly" }}>
						<Article style={{ paddingTop: isMobile ? 10 : 120 }}>
							<Text extreme>
								<Text color="#2572b5">Wishbox</Text> app will help you <Text color="#40B0C0">label</Text> the
								money you <Text color="#F06000">saved</Text>
							</Text>
						</Article>
						<Article>
							<Text extreme>
								Just create a <Text color="#2572b5">wishbox</Text> item and start{" "}
								<Text color="#F06000">depositing money</Text> from your{" "}
								<Text color="#E02060">wallet, bank, or drawer</Text>!
							</Text>
						</Article>
					</FlexColumn>
				</SectionLarge>
			</Section>
		</Layout>
	);
}
